import React, { useState, useEffect } from 'react'
import './ToTop.scss'
import {
  Zoom
} from '@material-ui/core'

import {withRouter} from 'react-router-dom'

function ToTop(props){
  const {
    location
  } = props

  const [height, setHeight] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  function handleScroll(event){
    setHeight(window.pageYOffset)
  }

  function handleClick(){
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  if(location.pathname !== '/stadapontunar'){
  return (
    <div className='totop__container'>
          <Zoom in={height > 10} >
          <div className='totop__wrapper' onClick={handleClick}>
      <img src='/images/arrow.png' className='totop__img' alt=""/>
    </div>
    </Zoom>
    </div>

  )
  } else {
    return null
  }
}

export default withRouter(ToTop)
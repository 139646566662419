import React from 'react'
import Loader from "../Loader/Loader";

export default function AsyncImportLoader({isLoading, error}){
  // Handle the loading state
  if (isLoading) {
    return <Loader/>;
  }
  // // Handle the error state
  // else if (error) {
  //   return <div>Villa kom upp. Reyndu aftur.</div>;
  // }
  else {
    return null;
  }
};
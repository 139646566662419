import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import './GDPRComponent.scss'
import { withRouter } from 'react-router-dom'

function GDPRComponent(props) {

  useEffect(() => {
    if (!window.localStorage.getItem('ispanGDPRstorage')) {
      setVisible(true)
    }
  }, [])

  const [visible, setVisible] = useState(false)

  const { history } = props

  function handleSamþykkja() {
    window.localStorage.setItem('ispanGDPRstorage', 'true')
    setVisible(false)
  }

  function handleSeeMore() {
    history.push('/upplysingar/vafrakokustefna')
  }

  if (visible) {
    return (
      <div className='GDPR'>
        <div className='GDPR__text'>Með því að nota síðuna samþykkir þú vafrakökustefnu Íspan</div>
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <Button style={{ color: 'white' }} variant='contained' color='secondary' onClick={handleSamþykkja}><div>Samþykkja</div></Button>
          <Button style={{ color: 'white' }} onClick={handleSeeMore}><div>Sjá nánar</div></Button>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default withRouter(GDPRComponent)
import React, { Fragment, useState, useEffect, Suspense, lazy } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Preview from "./Preview";

import { CssBaseline, IconButton } from "@material-ui/core";

import { SnackbarProvider } from "notistack";
import AOS from "aos";
import Loadable from "react-loadable";

import { createTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import { green, blue } from "@material-ui/core/colors";
import { ThemeProvider } from "@material-ui/styles";
import ToTop from "./Components/ToTop/ToTop";
import Loader from "./Components/Loader/Loader";
import AsyncImportLoader from "./Components/AsyncImportLoader/AsyncImportLoader";
import GDPRComponent from "./Components/GDPRComponent/GDPRComponent";

const FormPage = Loadable({
	loader: () => import("./Pages/FormPage/FormPage"),
	loading: AsyncImportLoader,
});

const UmIspanPage = Loadable({
	loader: () => import("./Pages/UmIspanPage/UmIspanPage"),
	loading: AsyncImportLoader,
});

const UpplysingarPage = Loadable({
	loader: () => import("./Pages/UpplysingarPage/UpplysingarPage"),
	loading: AsyncImportLoader,
});

const VoruPage = Loadable({
	loader: () => import("./Pages/VoruPage/VoruPage"),
	loading: AsyncImportLoader,
});

const CalculatorPage = Loadable({
	loader: () => import("./Pages/CalculatorPage/CalculatorPage"),
	loading: AsyncImportLoader,
});

const OrderStatusPage = Loadable({
	loader: () => import("./Pages/OrderStatusPage/OrderStatusPage"),
	loading: AsyncImportLoader,
});

const SearchPage = Loadable({
	loader: () => import("./Pages/SearchPage/SearchPage"),
	loading: AsyncImportLoader,
});

const HomePage = Loadable({
	loader: () => import("./Pages/HomePage/HomePage"),
	loading: AsyncImportLoader,
});

const ContactFormPage = Loadable({
	loader: () => import("./Pages/ContactFormPage/ContactFormPage"),
	loading: AsyncImportLoader,
});

const Help = Loadable({
	loader: () => import("./Help"),
	loading: AsyncImportLoader,
});

const NotFound = Loadable({
	loader: () => import("./Components/NotFound/NotFound"),
	loading: AsyncImportLoader,
});

function App(props) {
	const { prismicCtx } = props;

	const [openDialog, setOpenDialog] = useState(false);
	const [pontun, setPontun] = useState("pontun");
	const [footerHeight, setFooterHeight] = useState(0);

	const theme = createTheme({
		palette: {
			primary: {
				main: "#00909F",
			},
			secondary: {
				main: "#004488",
			},
		},
	});

	useEffect(() => {
		AOS.init({
			once: true,
		});
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3} preventDuplicate>
				<CssBaseline />
				<Router>
					<Switch>
						<Route
							exact
							path="/"
							render={(routeProps) => (
								<HomePage
									{...routeProps}
									prismicCtx={props.prismicCtx}
									openDialog={openDialog}
									setOpenDialog={setOpenDialog}
									setPontun={setPontun}
									footerHeight={footerHeight}
									setFooterHeight={setFooterHeight}
									pontun={pontun}
								/>
							)}
						/>

						<Route exact path="/help" component={Help} />

						{/* <Route exact path="/preview" render={routeProps => <Preview
              {...routeProps}
              prismicCtx={props.prismicCtx}
              footerHeight={footerHeight}
              setFooterHeight={setFooterHeight} />} /> */}

						<Route
							exact
							path="/form/:tegund"
							render={(routeProps) => (
								<FormPage
									{...routeProps}
									setOpenDialog={setOpenDialog}
									prismicCtx={props.prismicCtx}
									setPontun={setPontun}
									footerHeight={footerHeight}
									setFooterHeight={setFooterHeight}
									pontun={pontun}
								/>
							)}
						/>

						<Route
							exact
							path="/contact"
							render={(routeProps) => (
								<ContactFormPage
									{...routeProps}
									prismicCtx={prismicCtx}
									footerHeight={footerHeight}
									setFooterHeight={setFooterHeight}
								/>
							)}
						/>

						<Route
							exact
							path="/upplysingar/:uid"
							render={(routeProps) => (
								<UpplysingarPage
									{...routeProps}
									prismicCtx={props.prismicCtx}
									footerHeight={footerHeight}
									setFooterHeight={setFooterHeight}
								/>
							)}
						/>

						<Route
							exact
							path="/um_ispan/:uid"
							render={(routeProps) => (
								<UmIspanPage
									{...routeProps}
									prismicCtx={props.prismicCtx}
									footerHeight={footerHeight}
									setFooterHeight={setFooterHeight}
								/>
							)}
						/>

						<Route
							exact
							path="/vorur/:uid"
							render={(routeProps) => (
								<VoruPage
									{...routeProps}
									prismicCtx={prismicCtx}
									footerHeight={footerHeight}
									setFooterHeight={setFooterHeight}
								/>
							)}
						/>

						<Route
							exact
							path="/search"
							render={(routeProps) => (
								<SearchPage
									{...routeProps}
									prismicCtx={prismicCtx}
									footerHeight={footerHeight}
									setFooterHeight={setFooterHeight}
								/>
							)}
						/>

						<Route
							exact
							path="/reiknivel"
							render={(routeProps) => (
								<CalculatorPage
									{...routeProps}
									prismicCtx={prismicCtx}
									footerHeight={footerHeight}
									setFooterHeight={setFooterHeight}
								/>
							)}
						/>

						<Route
							exact
							path="/stadapontunar"
							render={(routeProps) => (
								<OrderStatusPage
									{...routeProps}
									prismicCtx={prismicCtx}
								/>
							)}
						/>

						<Route
							exact
							path="/stadapontunar/:id"
							render={(routeProps) => (
								<OrderStatusPage
									{...routeProps}
									prismicCtx={prismicCtx}
								/>
							)}
						/>

						<Route
							render={(routeProps) => (
								<NotFound
									{...routeProps}
									prismicCtx={prismicCtx}
								/>
							)}
						/>
					</Switch>
				</Router>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;

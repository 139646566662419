export default {

  apiEndpoint: 'https://ispan.prismic.io/api/v2',

  // -- Access token if the Master is not open
   accessToken: process.env.PRISMIC_TOKEN,

  // OAuth
  //  clientId: '',
  //  clientSecret: '',

  // -- Links resolution rules
  // This function will be used to generate links to Prismic.io documents
  // As your project grows, you should update this function according to your routes
  linkResolver(doc) {
     if(doc.linkType === 'Web') return doc.url
     
    if (doc.type === 'page') return `/page/${doc.uid}`;
    else if(doc.type === 'homepage') return '/';
    else if(doc.type === 'vorur') return `/vorur/${doc.uid}`
    else if(doc.type === 'um_ispan') return `/um_ispan/${doc.uid}` 
    else if (doc.type === 'upplysingar') return `/upplysingar/${doc.uid}`
    else if (doc.type === 'pontun') return '/form/pontun'
    else if (doc.type === 'verdfyrirspurn') return '/form/fyrirspurn'
    else if (doc.type === 'hafa_samband') return '/contact'
    else if (doc.type === 'reiknivel') return '/reiknivel'
    else if (doc.type === 'stada_pontunar') return '/stadapontunar'
    return '/';
  },
};
